import { createContext, useContext, useState } from 'react';
import NotFound from './NotFound';

const ErrorBoundaryContext = createContext<any>([() => {}, () => {}]);

export const useErrorBoundary = () => {
  return useContext(ErrorBoundaryContext);
};

export const ERROR_TYPES = {
  NOT_FOUND: 'notFound',
};

const ERROR_BODY = {
  [ERROR_TYPES.NOT_FOUND]: <NotFound />,
};

export default function CustomErrorBoundaryProvider({ children }: { children: React.ReactNode }) {
  const [error, setError] = useState(null);

  const resetError = () => {
    setError(null);
  };

  return (
    <ErrorBoundaryContext.Provider value={[setError, resetError]}>
      {error ? ERROR_BODY[error] : children}
    </ErrorBoundaryContext.Provider>
  );
}
