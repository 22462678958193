import { applicationProperties } from '@/constants/applicationProperties';
import { Web3AuthNotConnectedError } from '@/exceptions/exceptions';
import { UserEntity } from '@/generated/graphql';
import { userNewUrl, userProfileNewUrl, userTicketsUrl } from '@/helpers/url.helper';
import { useAuthStore } from '@/recoil/authStore/useAuthStore';
import { tempFormStore } from '@/recoil/tempFormStore/tempFormStore';
import { SiweEoaAddressRepository } from '@/repositories/SiweEoaAddressRepository';
import { SiweJwtRepository } from '@/repositories/SiweJwtRepository';
import { TicketRepository } from '@/repositories/TicketRepository';
import { UserTicketRepository } from '@/repositories/UserTicketRepository';
import {
  ADAPTER_EVENTS,
  ADAPTER_STATUS,
  ADAPTER_STATUS_TYPE,
  IAdapter,
  IProvider,
  WALLET_ADAPTER_TYPE,
  WalletInitializationError,
} from '@web3auth/base';
import { IPlugin } from '@web3auth/base-plugin';
import { ModalConfig, Web3Auth, Web3AuthOptions } from '@web3auth/modal';
import { useRouter } from 'next/router';
import { FunctionComponent, ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSetRecoilState } from 'recoil';
import { delay } from '@/utils/common';
import { SiweNonceRepository } from '@/repositories/SiweNonceRepository';
import { SiweMessage } from 'siwe';
import { ethers } from 'ethers';
import { UserRepository } from '@/repositories/UserRepository';
import { Web3AuthConnectedRepository } from '@/repositories/Web3AuthConnectedRepository';

export type Web3AuthModalConfig = {
  modalConfig: Record<WALLET_ADAPTER_TYPE, ModalConfig>;
};

export type Web3AuthContextConfig = {
  web3AuthOptions: Web3AuthOptions;
  modalConfig: Web3AuthModalConfig;
  adapters: IAdapter<unknown>[] | undefined;
  plugins: IPlugin[];
};

export interface IWeb3AuthContext {
  connectWeb3AuthAndSignInWithEthereum: () => Promise<void>;
  callbackedSignInWithEthereum: () => Promise<void>;
  web3Auth: Web3Auth | null | undefined;
  web3AuthLogout: (redirectUrl: string) => Promise<void>;
  isConnected: boolean;
  eoaAddress: string | null;
  handleNavigateUrl: (user: UserEntity | null | undefined) => Promise<void>;
}

export const Web3AuthContext = createContext<IWeb3AuthContext>({
  web3Auth: null,
  eoaAddress: '',
  isConnected: false,
  connectWeb3AuthAndSignInWithEthereum: async () => {},
  callbackedSignInWithEthereum: async () => {},
  web3AuthLogout: async () => {},
  handleNavigateUrl: async () => {},
});

export function useWeb3Auth(): IWeb3AuthContext {
  return useContext(Web3AuthContext);
}

interface IWeb3AuthProps {
  children?: ReactNode;
  config: Web3AuthContextConfig;
}

interface IWeb3AuthState extends IWeb3AuthProps {}

export const Web3AuthProvider: FunctionComponent<IWeb3AuthState> = ({ children, config }: IWeb3AuthProps) => {
  const router = useRouter();
  const [web3Auth, setWeb3Auth] = useState<Web3Auth | null>();
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [status, setStatus] = useState<ADAPTER_STATUS_TYPE | null>(null);
  const [provider, setProvider] = useState<IProvider | null>(null);
  const [eoaAddress, setEoaAddress] = useState<string | null>(null);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  const { setUser, setAuthenticated } = useAuthStore();
  const setTempFormData = useSetRecoilState(tempFormStore);

  useEffect(() => {
    initializeWeb3Auth();
  }, [config]);

  useEffect(() => {
    const notReadyListener = () => web3Auth && setStatus(web3Auth.status);
    const readyListener = () => {
      web3Auth && setStatus(web3Auth.status);
      setIsInitialized(true);
    };
    const connectedListener = () => {
      web3Auth && setStatus(web3Auth.status);
      setIsInitialized(true);
      setIsConnected(true);
    };
    const disconnectedListener = () => {
      web3Auth && setStatus(web3Auth.status);
      setProvider(null);
      setIsConnected(false);
    };
    const connectingListener = () => {
      web3Auth && setStatus(web3Auth.status);
    };
    const errorListener = () => {
      setStatus(ADAPTER_STATUS.ERRORED);
    };

    if (web3Auth) {
      // web3Auth is initialized here.
      setStatus(web3Auth.status);
      setProvider(web3Auth.provider);
      initModal();

      web3Auth.on(ADAPTER_EVENTS.NOT_READY, notReadyListener);
      web3Auth.on(ADAPTER_EVENTS.READY, readyListener);
      web3Auth.on(ADAPTER_EVENTS.CONNECTED, connectedListener);
      web3Auth.on(ADAPTER_EVENTS.DISCONNECTED, disconnectedListener);
      web3Auth.on(ADAPTER_EVENTS.CONNECTING, connectingListener);
      web3Auth.on(ADAPTER_EVENTS.ERRORED, errorListener);
    }

    return () => {
      if (web3Auth) {
        web3Auth.off(ADAPTER_EVENTS.NOT_READY, notReadyListener);
        web3Auth.off(ADAPTER_EVENTS.READY, readyListener);
        web3Auth.off(ADAPTER_EVENTS.CONNECTED, connectedListener);
        web3Auth.off(ADAPTER_EVENTS.DISCONNECTED, disconnectedListener);
        web3Auth.off(ADAPTER_EVENTS.CONNECTING, connectingListener);
        web3Auth.off(ADAPTER_EVENTS.ERRORED, errorListener);
      }
    };
  }, [web3Auth]);

  const initializeWeb3Auth = () => {
    resetHookState();
    const { web3AuthOptions, adapters = [], plugins = [] } = config;
    const web3AuthInstance = new Web3Auth(web3AuthOptions);

    if (adapters.length) {
      adapters.map((adapter) => web3AuthInstance.configureAdapter(adapter));
    }

    if (plugins.length) {
      plugins.forEach((plugin) => {
        web3AuthInstance.addPlugin(plugin);
      });
    }

    setWeb3Auth(web3AuthInstance);
  };

  const resetHookState = () => {
    setProvider(null);
    setEoaAddress(null);
    setStatus(null);
    setIsConnected(false);
    setIsInitialized(false);
  };

  const initModal = async () => {
    try {
      if (!web3Auth) throw WalletInitializationError.notReady();
      const { modalConfig } = config;
      await web3Auth.initModal(modalConfig);
    } catch (error) {
      console.error('Failed to initialize Web3Auth modal', error);
    }
  };

  // web3Authのログアウト処理
  const web3AuthLogout = async (redirectUrl: string) => {
    try {
      // siweの削除
      await SiweJwtRepository.removeSiweJwtFromBrowser();

      // web3Authのログアウト処理
      if (!isConnected) throw new Web3AuthNotConnectedError();
      await SiweEoaAddressRepository.remove();
      await SiweJwtRepository.removeSiweJwtFromBrowser();
      await TicketRepository.removeFromBrowser();
      await Web3AuthConnectedRepository.removeWeb3AuthConnected();
      await web3Auth?.logout();
    } catch (e) {
      console.error(e);
      // throw e;
    } finally {
      resetHookState();
      setAuthenticated(null);
      setUser(null);
      setTempFormData({});
      router.push(redirectUrl);
    }
  };

  // web3Authでログインを行った上でSIWEする
  const connectWeb3AuthAndSignInWithEthereum = async () => {
    console.log('connectWeb3AuthAndSignInWithEthereum: before', web3Auth);
    if (!web3Auth) return;
    try {
      const web3AuthProvider: IProvider | null = await web3Auth?.connect();
      if (!web3AuthProvider) return;

      console.log('connectWeb3AuthAndSignInWithEthereum', web3AuthProvider, web3Auth);

      await _signInWithEthereum(web3AuthProvider);
    } catch (e) {
      console.error(e);
      if (!`${e}`.includes('User closed the modal')) {
        throw e;
      }
    }
  };

  // SNSログイン後にcallbackした時にSIWEする
  const callbackedSignInWithEthereum = async () => {
    if (!web3Auth) return;
    try {
      const web3AuthProvider: IProvider | null = await web3Auth?.connect();
      if (!web3AuthProvider) return;

      await _signInWithEthereum(web3AuthProvider);
    } catch (e) {
      console.error(e);
      if (!`${e}`.includes('User closed the modal')) {
        throw e;
      }
    }
  };

  const _signInWithEthereum = async (web3AuthProvider: IProvider) => {
    try {
      // 1.web3AuthProviderをethers.jsのProviderに変換する
      const provider = new ethers.providers.Web3Provider(web3AuthProvider);
      // 2.変換したProviderからSignerを取得する
      const signer = await provider.getSigner();
      // 3.SignerからEOAアドレスを取得する
      const signinedEoaAddress = await signer.getAddress();
      // 4.接続先サーバーよりnonceを取得する
      const nonce = await SiweNonceRepository.challenge();
      // 5.SIWEメッセージを生成する
      const siweMessage = new SiweMessage({
        domain: applicationProperties.HOSTING_DOMAIN,
        address: signinedEoaAddress,
        statement: 'Sign in with Ethereum to the app.',
        uri: applicationProperties.HOSTING_URL,
        version: '1',
        chainId: 1,
        nonce,
      });
      // 6.メッセージをstring化し、署名を行う
      const message = siweMessage.prepareMessage();
      const signature = await signer.signMessage(message);

      // 7.署名したメッセージをサーバーに送信し、検証を行ったのち、JWTをキャッシュする
      const siweJwt = await SiweJwtRepository.login(siweMessage, signature);
      await SiweJwtRepository.saveSiweJwtToBrowser(siweJwt);
      setEoaAddress(signinedEoaAddress);

      // 8.ログイン後のユーザーアップデート処理
      const jwt = await SiweJwtRepository.getSiweJwtFromBrowser();
      await SiweEoaAddressRepository.save(signinedEoaAddress);
      if (!jwt) throw new Error('jwt is not found');
      setAuthenticated(jwt);

      const user = await UserRepository.findOneByJwt();
      // const user = await UserRepository.findOneByJwt(jwt.accessToken);

      await handleNavigateUrl(user);
    } catch (e: any) {
      console.error(e);
      if (e.response?.errors && e.response?.errors[0]?.extensions?.code === 'NO_USER_FOUND') {
        handleNavigateUrl(null);
      }
    }
  };

  const handleNavigateUrl = async (user: UserEntity | null | undefined) => {
    const ticketUniqueKeyEntrance = await TicketRepository.getFromBrowser();

    await delay();
    if (!user) {
      router.push(userNewUrl(ticketUniqueKeyEntrance as string));
      return;
    }

    setUser(user);

    if (!ticketUniqueKeyEntrance) {
      router.push(userTicketsUrl());
      return;
    }

    const droppable = await UserTicketRepository.droppable({ ticketUniqueKey: ticketUniqueKeyEntrance });
    if (droppable) {
      router.push(userTicketsUrl());
      toast.info('すでにこのチケットを取得しています');
      return;
    }

    router.push(userProfileNewUrl(ticketUniqueKeyEntrance));
  };

  const contextProvider = {
    connectWeb3AuthAndSignInWithEthereum,
    callbackedSignInWithEthereum,
    web3Auth,
    web3AuthLogout,
    isConnected,
    eoaAddress,
    handleNavigateUrl,
  };
  return <Web3AuthContext.Provider value={contextProvider}>{children}</Web3AuthContext.Provider>;
};
