import { graphqlApiClient } from '@/apis/GraphqlApiClient';
import { TicketDocument, TicketEntity, TicketQuery, TicketQueryVariables } from '@/generated/graphql';

const findOne = async (variables: TicketQueryVariables): Promise<TicketEntity> => {
  const ticketQuery = await graphqlApiClient().request<TicketQuery>(TicketDocument, variables);

  return ticketQuery.ticket;
};

const getFromBrowser = async (): Promise<string | null> => {
  const ticketUniqueKeyEntrance: string | null = await localStorage.getItem('nftticket.ticketUniqueKeyEntrance');
  if (ticketUniqueKeyEntrance) {
    return ticketUniqueKeyEntrance;
  } else {
    return null;
  }
};

const saveToBrowser = async (ticketUniqueKeyEntrance: string) => {
  try {
    localStorage.setItem('nftticket.ticketUniqueKeyEntrance', ticketUniqueKeyEntrance);
    window.dispatchEvent(new Event('storage'));
  } catch (e) {
    throw e;
  }
};

const removeFromBrowser = async () => {
  try {
    await localStorage.removeItem('nftticket.ticketUniqueKeyEntrance');
    window.dispatchEvent(new Event('storage'));
  } catch (e) {
    throw e;
  }
};

export const TicketRepository = { findOne, getFromBrowser, saveToBrowser, removeFromBrowser };
