const get = async (): Promise<string | null> => {
  const address: string | null = await localStorage.getItem('nftticket.siweEoaAddress');
  if (address) {
    return address;
  } else {
    return null;
  }
};

const save = async (redirectUrl: string) => {
  try {
    localStorage.setItem('nftticket.siweEoaAddress', redirectUrl);
    window.dispatchEvent(new Event('storage'));
  } catch (e) {
    throw e;
  }
};

const remove = async () => {
  try {
    await localStorage.removeItem('nftticket.siweEoaAddress');
    window.dispatchEvent(new Event('storage'));
  } catch (e) {
    throw e;
  }
};

export const SiweEoaAddressRepository = {
  get,
  save,
  remove,
};
