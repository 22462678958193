export default function NotFound() {
  return (
    <div className="w-screen h-screen flex items-center justify-center bg-bgError font-outfit">
      <div className="text-center text-white">
        <p className="font-bold text-[32px] leading-[40px] mb-[15px]">404</p>
        <p className="text-[20px] leading-[25px] font-medium">そのページはありません</p>
      </div>
    </div>
  );
}
