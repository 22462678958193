export const GA4_CUSTOM_EVENT = {
  SHOW_SCREEN: 'ShowScreen',
  USER_UNIQUE_KEY: 'UserUniqueKey',
};

export const GA4_SCREEN_NAME = {
  GET_TICKET_ENTRANCE_SCREEN: 'GetTicketEntranceScreen',
  WEB_3_AUTH_CALLBACK: 'Web3AuthCallback',
  LOGIN_SCREEN: 'LoginScreen',
  USER_REGISTRATION_FORM_SCREEN: 'UserRegistrationFormScreen',
  USER_TICKET_QUESTIONNAIRE_FORM_SCREEN: 'UserTicketQuestionnaireFormScreen',
  USER_TICKET_DROPPING_SCREEN: 'UserTicketDroppingScreen',
  TICKET_DROPPED_SCREEN: 'TicketDroppedScreen',
  TICKETS_SCREEN_EXISTS: 'TicketsScreen(exists)',
  TICKET_SHOW_SCREEN_NOT_CONSUMABLE: 'TicketShowScreen(Not consumable)',
  TICKET_SHOW_SCREEN_CONSUMABLE: 'TicketShowScreen(Consumable)',
  TICKETS_SCREEN_BLANK: 'TicketsScreen(blank)',
  TICKET_SHOW_SCREEN_CONSUMED: 'TicketShowScreen(Consumed)',
  TICKET_SHOW_SCREEN_EXPIRED: 'TicketShowScreen(expired)',
  TICKET_CONSUME_SCREEN: 'TicketConsumeScreen',
  POPUP_MENU_SCREEN: 'Popup Menu Screen',
  PWA_INSTALL_MODAL_IOS: 'PWAInstallModal(iOS)',
  PWA_INSTALL_MODAL_ANDROID: 'PWAInstallModal(Android)',
};
