export const signInUrl = () => '/auth/sign-in';
export const ticketEntranceUrl = (ticketUniqueKey: string) => `/tickets/${ticketUniqueKey}`;
export const userNewUrl = (ticketUniqueKey?: string) => `/users/new?ticketUniqueKey=${ticketUniqueKey}`;
export const userProfileNewUrl = (ticketUniqueKey: string) => `/tickets/${ticketUniqueKey}/user-tickets/new`;
export const web3AuthCallbackUrl = () => '/auth/callback';
export const userTicketsUrl = () => '/user-tickets';
export const userTicketDetailUrl = (ticketUniqueKey?: string) => `/user-tickets/${ticketUniqueKey}`;
export const ticketDroppedUrl = (ticketUniqueKey?: string) => `/tickets/${ticketUniqueKey}/dropped`;
export const inquiryUrl = () => '/inquiry';
export const healthCheckUrl = () => '/health';
