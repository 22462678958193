import { GraphQLClient } from 'graphql-request';
import { applicationProperties } from '@/constants/applicationProperties';
import { SiweJwt } from '@/entities/SiweJwt';
import { signInUrl } from '@/helpers/url.helper';
import { SiweJwtRepository } from '@/repositories/SiweJwtRepository';

const errorJwts = ['jwt expired', 'invalid signature'];

export const graphqlApiClient = () => {
  const atSiweJwt: string | null = localStorage.getItem('nftticket.siweJwt');
  const secretJwt = atSiweJwt ? new SiweJwt(JSON.parse(atSiweJwt)).accessToken : null;
  const headers = secretJwt
    ? {
        authorization: `Bearer ${secretJwt}`,
      }
    : undefined;

  return new GraphQLClient(applicationProperties.API_GQL_URL, {
    method: 'POST',
    headers,
    responseMiddleware: async (response: any) => {
      if (!response?.response?.errors) {
        return;
      }

      const errors = response.response.errors;
      const errorJwtExpired = errors.find((error: any) => errorJwts.includes(error.message));
      if (errorJwtExpired) {
        console.log('jwt expired');
        await SiweJwtRepository.removeSiweJwtFromBrowser();
        location.href = signInUrl();
      }
    },
  });
};
