import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const tempFormStore = atom<Record<string, string>>({
  key: 'tempFormStore',
  default: {},
  effects_UNSTABLE: [persistAtom],
});
