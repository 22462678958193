import { graphqlApiClient } from '@/apis/GraphqlApiClient';
import {
  ConsumeUserTicketDocument,
  ConsumeUserTicketMutation,
  ConsumeUserTicketMutationVariables,
  CreateUserTicketDocument,
  CreateUserTicketMutation,
  CreateUserTicketMutationVariables,
  DroppableUserTicketDocument,
  DroppableUserTicketQuery,
  DroppableUserTicketQueryVariables,
  UserTicketDocument,
  UserTicketEntity,
  UserTicketQuery,
  UserTicketQueryVariables,
  UserTicketsDocument,
  UserTicketsQuery,
  UserTicketsQueryVariables,
} from '@/generated/graphql';

const findAll = async (variables: UserTicketsQueryVariables): Promise<UserTicketEntity[]> => {
  const userTicketsQuery = await graphqlApiClient().request<UserTicketsQuery>(UserTicketsDocument, variables);

  return userTicketsQuery.userTickets as UserTicketEntity[];
};

const findOne = async (variables: UserTicketQueryVariables): Promise<UserTicketEntity> => {
  const userTicketQuery = await graphqlApiClient().request<UserTicketQuery>(UserTicketDocument, variables);

  return userTicketQuery.userTicket as UserTicketEntity;
};

const droppable = async (variables: DroppableUserTicketQueryVariables): Promise<boolean> => {
  const checkUserTicketQuery = await graphqlApiClient().request<DroppableUserTicketQuery>(
    DroppableUserTicketDocument,
    variables
  );

  return checkUserTicketQuery.droppableUserTicket;
};

const consume = async (variables: ConsumeUserTicketMutationVariables): Promise<UserTicketEntity> => {
  const consumeUserTicketMutation = await graphqlApiClient().request<ConsumeUserTicketMutation>(
    ConsumeUserTicketDocument,
    variables
  );

  return consumeUserTicketMutation.consumeUserTicket as UserTicketEntity;
};

const drop = async (variables: CreateUserTicketMutationVariables): Promise<UserTicketEntity> => {
  const createUserTicketMutation = await graphqlApiClient().request<CreateUserTicketMutation>(
    CreateUserTicketDocument,
    variables
  );

  return createUserTicketMutation.createUserTicket as UserTicketEntity;
};

const getFromBrowser = async (): Promise<string | null> => {
  const userTicketUniqueKeyDropped: string | null = await localStorage.getItem('nftticket.userTicketUniqueKeyDropped');
  if (userTicketUniqueKeyDropped) {
    return userTicketUniqueKeyDropped;
  } else {
    return null;
  }
};

const saveToBrowser = async (userTicketUniqueKeyDropped: string) => {
  try {
    localStorage.setItem('nftticket.userTicketUniqueKeyDropped', userTicketUniqueKeyDropped);
    window.dispatchEvent(new Event('storage'));
  } catch (e) {
    throw e;
  }
};

const removeFromBrowser = async () => {
  try {
    await localStorage.removeItem('nftticket.userTicketUniqueKeyDropped');
    window.dispatchEvent(new Event('storage'));
  } catch (e) {
    throw e;
  }
};

export const UserTicketRepository = {
  findAll,
  findOne,
  droppable,
  consume,
  drop,
  getFromBrowser,
  saveToBrowser,
  removeFromBrowser,
};
