import {
  healthCheckUrl,
  inquiryUrl,
  signInUrl,
  ticketEntranceUrl,
  userTicketsUrl,
  web3AuthCallbackUrl,
} from '@/helpers/url.helper';
import { useAuthStore } from '@/recoil/authStore/useAuthStore';
import { SiweJwtRepository } from '@/repositories/SiweJwtRepository';
import { useRouter } from 'next/router';
import { Fragment, ReactNode, useEffect, useState } from 'react';
import useSWR from 'swr';

const Middleware = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  const { uniqueKey } = router.query;
  const fullPath = router.asPath;
  const [loaded, setLoaded] = useState(false);
  const { user, isAuthenticated, setAuthenticated } = useAuthStore();

  const { data } = useSWR(
    'authKey',
    async () => {
      return await SiweJwtRepository.getSiweJwtFromBrowser();
    },
    {
      refreshInterval: 5000,
    }
  );

  useEffect(() => {
    setAuthenticated(data);
  }, [data, setAuthenticated]);

  useEffect(() => {
    const handleRedirectRouter = async () => {
      if (!fullPath || !loaded || !router.isReady) {
        return;
      }

      const publicUrls = [signInUrl(), ticketEntranceUrl(uniqueKey as string), healthCheckUrl(), inquiryUrl()];
      if (!isAuthenticated && (publicUrls.includes(fullPath) || router.pathname === web3AuthCallbackUrl())) {
        return;
      }

      if (!isAuthenticated && !publicUrls.includes(fullPath)) {
        router.push(signInUrl());
        return;
      }

      // const userNewUrls = userNewUrl(uniqueKey as string);
      // if (isAuthenticated && !user && fullPath !== userNewUrls) {
      //   router.push(userNewUrls);
      //   return;
      // }

      if (isAuthenticated && fullPath === signInUrl() && user) {
        router.push(userTicketsUrl());
        return;
      }

      return;
    };

    handleRedirectRouter();
  }, [fullPath, isAuthenticated, loaded, router, uniqueKey, user]);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return <Fragment>{children}</Fragment>;
};

export default Middleware;
