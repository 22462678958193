import { useRecoilState } from 'recoil';
import { SiweJwt } from '@/entities/SiweJwt';
import { authenticatedStore, userStore } from './authStore';

const isAvailableJwt = (jwt: SiweJwt) => {
  return jwt.expiresAt > Date.now() && (jwt.accessToken ? true : false);
};

export const useAuthStore = () => {
  const [authenticated, setAuthenticated] = useRecoilState(authenticatedStore);
  const [user, setUser] = useRecoilState(userStore);

  const isAuthenticated = () => {
    const jwtAvailable = authenticated ? isAvailableJwt(authenticated) : false;
    return jwtAvailable;
  };

  return { user, authenticated, isAuthenticated: isAuthenticated(), setUser, setAuthenticated };
};
