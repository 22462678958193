export const applicationProperties = {
  HOSTING_DOMAIN: process.env.NEXT_PUBLIC_HOSTING_DOMAIN || 'localhost:4000',
  HOSTING_URL: process.env.NEXT_PUBLIC_HOSTING_URL || 'http://localhost:4000',
  GOOGLE_ANALYTICS_ID: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID || '',
  ROLLBAR_ACCESS_TOKEN: process.env.NEXT_PUBLIC_ROLLBAR_ACCESS_TOKEN || '',
  ROLLBAR_ENVIRONMENT: process.env.NEXT_PUBLIC_ROLLBAR_ENVIRONMENT || '',
  API_GQL_URL: process.env.NEXT_PUBLIC_API_GQL_URL || 'http://localhost:4000/graphql',
  API_REST_URL: process.env.NEXT_PUBLIC_API_REST_URL || 'http://localhost:4000/',
  WEB3AUTH_CLIENT_ID: process.env.NEXT_PUBLIC_WEB3_AUTH_CLIENT_ID || '',
  WEB3AUTH_AUTH_NETWORK: process.env.NEXT_PUBLIC_WEB3_AUTH_NETWORK || 'sapphire_devnet',
};
