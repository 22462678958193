import { applicationProperties } from '@/constants/applicationProperties';
import { web3AuthCallbackUrl } from '@/helpers/url.helper';
import { CHAIN_NAMESPACES, CustomChainConfig, WALLET_ADAPTERS } from '@web3auth/base';
import { EthereumPrivateKeyProvider } from '@web3auth/ethereum-provider';
import { MetamaskAdapter } from '@web3auth/metamask-adapter';
import { Web3AuthOptions } from '@web3auth/modal';
import { OpenloginAdapter } from '@web3auth/openlogin-adapter';
import { Web3AuthContextConfig } from './Web3Auth';

const CHAIN_CONFIG = {
  mainnet: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: '0x1',
    rpcTarget: 'https://rpc.ankr.com/eth',
    displayName: 'Ethereum Mainnet',
    blockExplorer: 'https://etherscan.io',
    ticker: 'ETH',
    tickerName: 'Ethereum',
  } as CustomChainConfig,
};

const clientId = applicationProperties.WEB3AUTH_CLIENT_ID;
const currentChainConfig = CHAIN_CONFIG.mainnet;
const web3AuthNetwork = applicationProperties.WEB3AUTH_AUTH_NETWORK as Web3AuthOptions['web3AuthNetwork'];
const privateKeyProvider = new EthereumPrivateKeyProvider({ config: { chainConfig: currentChainConfig } });

const web3AuthOptions: Web3AuthOptions = {
  clientId,
  web3AuthNetwork,
  chainConfig: currentChainConfig,
  uiConfig: {
    appName: 'ASTEEDA',
    appUrl: applicationProperties.HOSTING_URL,
    mode: 'light',
    // logoLight: `${applicationProperties.HOSTING_URL}/assets/logo_asteeda.svg`,
    defaultLanguage: 'ja',
    tncLink: { ja: 'https://drive.google.com/file/d/19R26joGpQMfNBAkT4fDwb2EFy0FW0b0_/view' },
    privacyPolicy: { ja: 'https://www.scsk.jp/privacy.html' },
  },
};

const openloginAdapter = new OpenloginAdapter({
  privateKeyProvider,
  loginSettings: {
    mfaLevel: 'none', // default, optional, mandatory, none
  },
  adapterSettings: {
    uxMode: 'redirect', // safariは　popup が使えないため明示的に redirect を指定する
    redirectUrl: `${applicationProperties.HOSTING_URL}${web3AuthCallbackUrl()}`,
    whiteLabel: {
      mode: 'light',
    },
  },
});

const metamaskAdapter = new MetamaskAdapter({
  clientId,
  web3AuthNetwork,
  chainConfig: currentChainConfig,
});

const modalConfig = {
  modalConfig: {
    [WALLET_ADAPTERS.OPENLOGIN]: {
      label: 'openlogin',
      loginMethods: {
        apple: {
          name: 'apple',
          showOnModal: true,
        },
        email_passwordless: {
          name: 'email_passwordless',
          showOnModal: true,
        },
        facebook: {
          name: 'facebook',
          showOnModal: false,
        },
        reddit: {
          name: 'reddit',
          showOnModal: false,
        },
        discord: {
          name: 'discord',
          showOnModal: false,
        },
        twitch: {
          name: 'twitch',
          showOnModal: false,
        },
        line: {
          name: 'line',
          showOnModal: false,
        },
        github: {
          name: 'github',
          showOnModal: false,
        },
        kakao: {
          name: 'kakao',
          showOnModal: false,
        },
        linkedin: {
          name: 'linkedin',
          showOnModal: false,
        },
        twitter: {
          name: 'twitter',
          showOnModal: false,
        },
        weibo: {
          name: 'weibo',
          showOnModal: false,
        },
        wechat: {
          name: 'wechat',
          showOnModal: false,
        },
        sms_passwordless: {
          name: 'phone',
          showOnModal: false,
        },
      },
      showOnModal: true,
    },
    [WALLET_ADAPTERS.WALLET_CONNECT_V2]: {
      label: 'walletConnect',
      showOnModal: false,
    },
    [WALLET_ADAPTERS.COINBASE]: {
      label: 'COINBASE',
      showOnModal: false,
    },
    [WALLET_ADAPTERS.METAMASK]: {
      label: 'METAMASK',
      showOnModal: false,
    },
    [WALLET_ADAPTERS.PHANTOM]: {
      label: 'PHANTOM',
      showOnModal: false,
    },
    [WALLET_ADAPTERS.SLOPE]: {
      label: 'SLOPE',
      showOnModal: false,
    },
    [WALLET_ADAPTERS.SOLFLARE]: {
      label: 'SOLFLARE',
      showOnModal: false,
    },
    [WALLET_ADAPTERS.TORUS_EVM]: {
      label: 'TORUS_EVM',
      showOnModal: false,
    },
    [WALLET_ADAPTERS.TORUS_SOLANA]: {
      label: 'TORUS_SOLANA',
      showOnModal: false,
    },
  },
};

export const web3AuthContextConfig: Web3AuthContextConfig = {
  web3AuthOptions,
  modalConfig,
  adapters: [openloginAdapter, metamaskAdapter],
  plugins: [],
};
