import Menu from './Menu';
import {
  signInUrl,
  ticketDroppedUrl,
  ticketEntranceUrl,
  userTicketDetailUrl,
  userTicketsUrl,
} from '@/helpers/url.helper';
import { useRouter } from 'next/router';

const showMenuPaths = [
  ticketDroppedUrl('[uniqueKey]'),
  userTicketsUrl(),
  userTicketDetailUrl('[uniqueKey]'),
  ticketEntranceUrl('[uniqueKey]'),
  signInUrl(),
];

const Header = () => {
  const { pathname } = useRouter();

  const isShowMenu = showMenuPaths.includes(pathname);

  return (
    <div className="flex justify-between">
      {isShowMenu ? <Menu /> : <div className="invisible w-[44px]"></div>}
      <img src="/assets/logo_asteeda.png" alt="Logo Asteeda" className="w-[47px] h-[45px]" />
      <div className="invisible w-[44px]"></div>
    </div>
  );
};

export default Header;
